import React, { Component } from 'react';
import _get from 'lodash/get';
import Layout from 'components/layout'
import AsicHeader from 'components/asic/AsicHeader'
import Bottom from 'components/Bottom/Bottom';
import GetStarted from 'components/GetStarted';
import AsicContainer from 'components/asic/AsicContainer';
import Cookies from 'js-cookie'
class Asicvvs extends Component {
  render () {
    Cookies.set('id_referral', '113015', {expires: 365, path: '/', domain: '.hiveos.farm'});

    return (
      <Layout page="asic" withHeader={false} lang={_get(this.props, 'pageContext.lang', 'en')}>
        <AsicHeader />
        <AsicContainer />
        <GetStarted />
        <Bottom />
      </Layout>
    );
  }
}

export default Asicvvs;
